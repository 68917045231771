export interface ProductBasicInfo {
  productName: string;
  productId: string;
  productPrice: number;
  productProfit: number;
  Category: string;
  currency: string;
  productPriceRange: PriceRangeModel;
  country: string;
  type: 'bundle' | 'product';
  bundleVariants: BundleVariantModel[];
}

export interface ProductAdditionalInfo {
  productDescription: string;
  productSpecifications: string;
  productHowToUse: string;
  productEmbeddedVideos: string[];
  productKeywords: string[];
}

export interface Variant {
  inStock: boolean;
  extraImage1: string;
  extraImage2: string;
  extraImage3: string;
  extraImage4: string;
  extraImage5: string;
  extraImage6: string;
  isExpired: boolean;
  visibleToSellers: any[];
  orderCount: number;
  additionalMedia: string[];
  embeddedVideos: string[];
  _id: string;
  productAvailability: string;
  productName: string;
  productPrice: number;
  productProfit: number;
  productQuantity: number;
  productDescription: string;
  specifications: string;
  howToUse: string;
  prodID: string;
  productWeight: number;
  Category: string;
  categoryId: string;
  productPicture: string;
  seller: string;
  sellerName: string;
  createdAt: string;
  updatedAt: string;
  attributes: { type: string; value: string }[];
  sale: VariantSalesModel;
  pricingRange: PriceRangeModel;
  discountForAdditionalQuantity: QuantityDiscountModel;
  availabilityInfo: ProductAvailabilityModel;
  keywords: string[];
  country: string;
  type: 'bundle' | 'product';
  bundleVariants: BundleVariantModel[];
}

export interface BundleVariantModel {
  id: string;
  picture: string;
  quantity: string;
  _id: string;
}

export interface ProductAvailabilityModel {
  orderable: boolean;
  stockInfo: {
    expiresAt: string;
    remainingStock: number;
    totalLockedStock: number;
  };
  visibility: {
    expiresAt: string;
    visible: boolean;
  };
}

export interface QuantityDiscountModel {
  amount: number;
  discountedPrice: number;
  enabled: boolean;
  merchantProfit: number;
}

export interface AdditionalQuantityModel {
  amount: number;
  enabled: boolean;
}

export interface Analytics {
  confirmationRate?: number;
  deliveryRate?: number;
  createdAt?: string;
  updatedAt?: string;
  featureAvailable: boolean;
}

export interface VariantTableRowData extends Variant {
  disabled: boolean;
  preSelected: boolean;
  isMissingImages: boolean;
}

export interface Attribute {
  name?: string;
}

export interface AttributeValue {
  type?: string;
  value?: string;
}

export interface AttributeSet {
  type?: string;
  category?: string;
  attributes?: Attribute[];
}

export interface VariantGroup {
  name: string;
  categoryId: string;
  visibleToSellers: string[];
  variants: VariantTableRowData[];
  attributeSets: AttributeSet[];
  createdAt?: string;
  isExpired?: boolean;
  primaryVariant?: string;
  updatedAt?: string;
  __v?: number;
  _id?: string;
}

export interface ProductVariantsData {
  colorVariantArr: string[];
  colorNamesArray: string[];
  sizeVariantArr: string[];
  selectedColor: string;
  selectedSize: string;
}

export enum ProductAvailability {
  available = 'available',
  not_available = 'not_available',
  available_for_preorder = 'available_for_preorder',
  available_with_high_qty = 'available_with_high_qty',
  available_with_low_qty = 'available_with_low_qty',
  draft = 'draft',
}

export interface VariantSalesModel {
  productPrice: number;
  productProfit: number;
  saleStartsAt: string;
  saleExpiresAt: string;
}

export interface PriceRangeModel {
  updateAt: Date;
  createdAt: Date;
  min: Number;
  max: Number;
  expectedProfitMin: Number;
  expectedProfitMax: Number;
}
